module.exports = [{
      plugin: require('/app/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/app/src/layouts/index.js"},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
